import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../../utils/navigationUtils";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  changeStatus,
  openModal,
} from "../../redux/slices/loginModal/modalSlice";
import LanguageMenu from "../languageMenu/LanguageMenu";
import MobileHeader from "./MobileHeader";
import ButtonGroup from "./ButtonGroup";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const IsArmenian = localStorage.getItem("i18nextLng");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  const navbar = [
    { name: t("services"), route: "/services" },
    { name: t("partners"), route: "/partners" },
    { name: t("aboutUs"), route: "/about" },
    { name: t("contactUS"), route: "/contact" },
  ];

  const handleNavigation = (route) => {
    navigateTo(navigate, route);
    setActiveRoute(route);
    setMenuOpen(false);
  };

  const isActiveRoute = (route) => {
    return activeRoute === route || activeRoute.endsWith(route);
  };

  const toggleSignInModal = () => {
    dispatch(openModal(true));
    dispatch(changeStatus("SignIn"));
  };

  const toggleSignUpModal = () => {
    dispatch(openModal(true));
    dispatch(changeStatus("SignUp"));
  };

  // Determine styles based on language
  const navStyle =
    IsArmenian === "am"
      ? " lg:gap-3 lg:prose-t13_regular xl:gap-8 xl:prose-t16_regular "
      : "gap-8 prose-t14_regular xl:prose-t16_regular";

  return (
    <>
      {isMobile ? (
        <MobileHeader
          menuOpen={menuOpen}
          setMenuOpen={setMenuOpen}
          navbar={navbar}
          activeRoute={activeRoute}
          handleNavigation={handleNavigation}
          toggleSignInModal={toggleSignInModal}
          toggleSignUpModal={toggleSignUpModal}
        />
      ) : (
        <div className="bg-white w-full min-h-[80px] flex justify-between shadow-md px-1 lg:px-6 sticky top-0 z-40">
          <div className="flex items-center gap-4 lg:gap-10">
            {/* Logo */}
            <div className="py-3">
              <img
                src="/assets/header-logo.png"
                alt="my service"
                onClick={() => handleNavigation("")}
                className="cursor-pointer"
              />
            </div>
            {/* Desktop menu navbar */}
            <div className={`flex ${navStyle}`}>
              {navbar.map((item) => (
                <div
                  key={item.name}
                  className={`flex  ${
                    isActiveRoute(item.route)
                      ? "border-b border-primary min-h-[80px] "
                      : ""
                  }`}
                >
                  <button
                    onClick={() => handleNavigation(item.route)}
                    className={`text-a050 flex items-center h-full ${
                      isActiveRoute(item.route)
                        ? "text-black"
                        : "hover:text-primary"
                    } ${isActiveRoute(item.route) ? "disabled-hover" : ""}`}
                    disabled={isActiveRoute(item.route)}
                  >
                    {item.name}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex items-center xl:gap-8">
            <div className="hidden md:flex">
              <LanguageMenu />
            </div>
            <ButtonGroup
              toggleSignInModal={toggleSignInModal}
              toggleSignUpModal={toggleSignUpModal}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
