import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../utils/navigationUtils";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import SearchInput from "../components/searchInput/SearchInput";
import ScrollToTopButton from "../components/home/ScrollToTopButton";
import TrustBy from "../components/home/TrustBy";
import CustomerSlider from "../components/home/CustomerSlider";
import Faq from "../components/home/Faq";
import ServiceList from "../components/services/ServiceList";
import { secondaryFixed } from "../ui-elements/button";
import "../components/home/css/logoBlur.css";

const Home = () => {
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const isArmenian = localStorage.getItem("language");

  const data = [
    { id: 1, name: "Cleaning", url: "assets/services/1.png" },
    { id: 2, name: "Handyman", url: "assets/services/2.png" },
    { id: 3, name: "Nurse", url: "assets/services/3.png" },
    { id: 4, name: "Lecturer", url: "assets/services/4.png" },
    { id: 5, name: "Babysitter", url: "assets/services/5.png" },
    { id: 6, name: "Dog sitter", url: "assets/services/6.png" },
    { id: 7, name: "Massage", url: "assets/services/7.png" },
    { id: 8, name: "Psychologist", url: "assets/services/8.png" },
    { id: 9, name: "Psychology", url: "logo1.png" },
    { id: 10, name: "Plumbing", url: "logo2.png" },
    { id: 11, name: "Cleaning", url: "logo3.png" },
    { id: 12, name: "Construction", url: "logo4.png" },
    { id: 13, name: "Electricity", url: "logo5.png" },
    { id: 14, name: "Barber", url: "logo6.png" },
    { id: 15, name: "Makeup", url: "logo6.png" },
    { id: 16, name: "Interior Design", url: "logo6.png" },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const yOffset = window.pageYOffset;
    if (yOffset > 500) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" w-full h-full flex flex-col justify-center items-center relative">
      <div
        className=" w-full  mb-[70px]"
        style={{
          backgroundImage: `url(${
            windowWidth <= 430 ? "assets/mobileFrame.webp" : "assets/Frame.webp"
          })`,
          backgroundSize: windowWidth <= 430 ? "contain" : "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: windowWidth <= 430 ? "710px" : "894px",
        }}
      >
        <div className="relative h-full z-10 flex flex-col items-center justify-center gap-7">
          <div
            className={`${
              windowWidth >= 1024
                ? "  flex flex-col justify-center items-center gap-7"
                : "flex flex-col items-center justify-center"
            }`}
            style={{
              backgroundImage: `url(${
                windowWidth >= 1024 ? "assets/Ellipse.png" : ""
              })`,
              backgroundSize: windowWidth >= 1024 ? "contain" : "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              alt="logo"
              src="/assets/logo.png"
              className="hidden lg:flex  object-cover cursor-pointer"
              onClick={() => navigateTo(navigate, "/")}
            />

            <SearchInput />
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="mb-[70px]">
        <TrustBy />
      </div>
      {/* Section 3 */}
      <div className="mb-[70px]">
        <div className="flex flex-col items-center gap-4 pb-8 lg:pb-10 px-2 lg:px-0">
          <div
            className={`${
              isArmenian === "am"
                ? "flex flex-col lg:flex-row  items-center justify-center "
                : "flex flex-row gap-2"
            }  lg:items-center lg:gap-2 `}
          >
            <h2 className="prose-t32_regular lg:prose-t40_regular">
              {t("our")}
            </h2>
            <h2 className="text-primary prose-t32_regular lg:prose-t40_bold">
              {t("services1")}
            </h2>
          </div>
          <p className="text-a070 text-center max-w-[552px]">
            {t("ourServiceDescription")}
          </p>
        </div>
        <ServiceList data={data} />
        <div className="flex justify-center mt-8">
          <button
            className={secondaryFixed}
            onClick={() => navigateTo(navigate, "/services?serviceList=true")}
          >
            {t("seeAll")}
          </button>
        </div>
      </div>
      {/* Section 4 */}
      {/* <div className="max-w-[340px] sm:max-w-[680px] xl:max-w-[1210px] h-[352px] mb-[70px]">
        <CustomerSlider />
      </div> */}
      {/* Section 5 */}
      <div className=" mb-[70px]">
        <Faq />
      </div>
      <AnimatePresence>
        {showButton && <ScrollToTopButton onClick={scrollToTop} />}
      </AnimatePresence>
    </div>
  );
};

export default Home;
