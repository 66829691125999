import React, { useState } from "react";
import PaymentQuestions from "../components/payment/PaymentQuestions";

const Payment = () => {
  const data = {
    step1: {
      title: "Fabric upholstered furniture",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      questions: [
        { code: "q1", question: "Double sofa/10,000-15,000 AMD" },
        { code: "q2", question: "Sofa for 4 seats or more/20,000-25,000 AMD" },
        { code: "q3", question: "Pillow for sofa/500-1000 AMD" },
        { code: "q4", question: "Armchair/5000-8000 AMD" },
        { code: "q5", question: "Chair/800-1200 AMD" },
      ],
    },
    step2: {
      title: "harc hamar erku",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      questions: [
        { code: "q6", question: "Mattress 2 place/15000-20000 AMD" },
        { code: "q7", question: "Armchair/5000-8000 AMD" },
        { code: "q8", question: "Pillow for sofa/500-1000 AMD" },
        { code: "q9", question: "Armchair/5000-8000 AMD" },
        { code: "q10", question: "Chair/800-1200 AMD" },
      ],
    },
  };
  return <div>{/* <PaymentQuestions data={data} /> */}</div>;
};

export default Payment;
