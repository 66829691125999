import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation hooks

const LanguageMenu = () => {
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState("am");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Detect and set language on mount
    const detectLanguage = async () => {
      const savedLang = localStorage.getItem("language") || "am"; // Default to 'am'
      await i18n.changeLanguage(savedLang);
      setActiveLang(savedLang);
    };

    detectLanguage();
  }, [i18n]);

  const handleChange = async (lng) => {
    await i18n.changeLanguage(lng);
    setActiveLang(lng);
    localStorage.setItem("language", lng); // Save the selected language to localStorage

    // Update the URL to include the selected language prefix
    const newPathname = `/${lng}${location.pathname.replace(
      /^\/(am|ru|en)/,
      ""
    )}`; // Adjust path to new language
    navigate(newPathname);
  };

  const buttonStyle = " prose-t12_regular lg:prose-t14_medium ";
  const passiveTextStyle = "text-a040 hover:text-primary cursor-pointer";

  return (
    <div className="">
      <div className="flex items-center ">
        <span
          onClick={() => handleChange("am")}
          className={`${buttonStyle} ${
            activeLang === "am" ? "" : passiveTextStyle
          }`}
        >
          &nbsp;Հայ
        </span>
        <span className="text-a040"> /</span>
        <span
          onClick={() => handleChange("ru")}
          className={`${buttonStyle} ${
            activeLang === "ru" ? "" : passiveTextStyle
          }`}
        >
          &nbsp;Рус&nbsp;
        </span>
        <span className="text-a040"> / </span>
        <span
          onClick={() => handleChange("en")}
          className={`${buttonStyle} ${
            activeLang === "en" ? "" : passiveTextStyle
          }`}
        >
          Eng&nbsp;
        </span>
      </div>
    </div>
  );
};

export default LanguageMenu;
