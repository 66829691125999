import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    isOpen: false,
    status: "",
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = action.payload;
    },
    closeModal: (state, action) => {
      state.isOpen = action.payload;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { openModal, closeModal, changeStatus } = modalSlice.actions;

export const selectModalIsOpen = (state) => state.modal.isOpen;
export const selectModalStatus = (state) => state.modal.status;

export default modalSlice.reducer;
