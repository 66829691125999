import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StarRating from "../../../helpers/StarRating";
import { useNavigate } from "react-router-dom";

const MobileOrderHistory = () => {
  const { t } = useTranslation();

  const mockData = [
    {
      id: 1,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
    {
      id: 2,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "3",
    },
    {
      id: 3,
      serviceProvider: "My Home",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "5",
    },
    {
      id: 5,
      serviceProvider: "My Home",
      status: "Finished",
      segment: "Construction",
      date: "1.04.2024",
      serviceRating: "5",
    },
    {
      id: 6,
      serviceProvider: "My Home",
      status: "Finished",
      segment: "Construction",
      date: "1.04.2024",
      serviceRating: "4",
    },
    {
      id: 7,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
    {
      id: 8,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
    {
      id: 9,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
    {
      id: 10,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
    {
      id: 11,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
    {
      id: 12,
      serviceProvider: "Nor tun",
      status: "Finished",
      segment: "Construction",
      date: "12.04.2024",
      serviceRating: "4",
    },
  ];

  const [reorderRow, setReorderRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 10;

  const totalPages = Math.ceil(mockData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const currentData = mockData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleReorderClick = () => {
    // navigate("/reorder-page"); // Replace with the desired reorder page
  };

  return (
    <div>
      {currentData.map((item, index) => (
        <div
          key={item.id}
          className="w-[336px] h-[162px] flex flex-col border shadow-md rounded-xl mb-3 relative"
          onMouseEnter={() => setReorderRow(item.id)}
          onMouseLeave={() => setReorderRow(null)}
        >
          {/* Top Section */}
          <div className="h-1/2 flex justify-between items-center bg-a05 px-3 py-3 relative">
            <div className="flex flex-col gap-1.5">
              <span className="prose-t10_regular text-a050">
                Service Provider
              </span>
              <span className="prose-t13_regular text-a070">
                {item.serviceProvider}
              </span>
            </div>
            <div className="pr-6">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
              >
                <path
                  d="M6 14C5.45 14 4.97917 13.8042 4.5875 13.4125C4.19583 13.0208 4 12.55 4 12C4 11.45 4.19583 10.9792 4.5875 10.5875C4.97917 10.1958 5.45 10 6 10C6.55 10 7.02083 10.1958 7.4125 10.5875C7.80417 10.9792 8 11.45 8 12C8 12.55 7.80417 13.0208 7.4125 13.4125C7.02083 13.8042 6.55 14 6 14ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM18 14C17.45 14 16.9792 13.8042 16.5875 13.4125C16.1958 13.0208 16 12.55 16 12C16 11.45 16.1958 10.9792 16.5875 10.5875C16.9792 10.1958 17.45 10 18 10C18.55 10 19.0208 10.1958 19.4125 10.5875C19.8042 10.9792 20 11.45 20 12C20 12.55 19.8042 13.0208 19.4125 13.4125C19.0208 13.8042 18.55 14 18 14Z"
                  fill="#666666"
                />
              </svg>
            </div>
            <div className="flex flex-col gap-1.5 text-right">
              <span className="prose-t10_regular text-a050 ">Status </span>
              <span className="prose-t13_regular text-a070">{item.status}</span>
            </div>
            {reorderRow === item.id && (
              <button
                onClick={handleReorderClick}
                className="absolute top-[75px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[129px] h-[52px] shadow-lg rounded-md bg-white text-black text-center z-10 px-4"
              >
                {t("reorder")}
              </button>
            )}
          </div>
          {/* Below Section */}
          <div className="h-1/2 flex justify-between bg-white px-3 py-3">
            <div className="w-4/5 flex flex-col gap-1.5">
              <span className="prose-t10_regular text-a050">Segment </span>
              <span className="prose-t13_regular text-a070">
                {item.segment}
              </span>
            </div>
            <div className="w-1/3 flex flex-col items-center gap-1.5  ">
              <span className="prose-t10_regular text-a050">Start Date </span>
              <span className="prose-t13_regular text-a070">{item.date}</span>
            </div>
            <div className="flex flex-col items-end gap-1">
              <span className="prose-t10_regular text-a050">
                Service Rating
              </span>
              <span className="flex gap-1.5 w-3/4">
                {<StarRating count={item.serviceRating} />}
              </span>
            </div>
          </div>
        </div>
      ))}
      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z"
              fill="#EE5222"
            />
          </svg>
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={` ${
              currentPage === index + 1
                ? "active text-white bg-primary border border-primary"
                : "text-a060 border border-a030"
            }  h-6 w-6 prose-t16_medium flex items-center justify-center`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6 12L8 7.4L9.4 6L15.4 12L9.4 18L8 16.6L12.6 12Z"
              fill="#EE5222"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default MobileOrderHistory;
