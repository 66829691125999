import React from "react";
import { useTranslation } from "react-i18next";
import PartnersCard from "./partnersCard/PartnersCard";

const PartnerList = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[40px]  flex flex-col items-center mb-[32px] ">
      <div className="flex flex-col items-center md:items-start gap-[40px]">
        <div className="w-full flex flex-col items-center sm:items-center  xl:items-start gap-2 ">
          <div className="prose-t24_bold">
            <span className="text-black ">
              {t("selectProvider")}
              {/* <span className="text-a050"> {t("construction")}</span> */}
            </span>
          </div>
          <p className="w-[336px] xl:w-full text-a070">{t("youAreJustOne")}</p>
        </div>
        <PartnersCard />
      </div>
    </div>
  );
};

export default PartnerList;
