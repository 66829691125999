import React from "react";
import { useTranslation } from "react-i18next";
import {
  formDescription,
  formTitle,
} from "../../ui-elements/formStyles/formStyle.js";
import { useDispatch } from "react-redux";
import SignUpForm from "./Form/SignUpForm";
import { changeStatus } from "../../redux/slices/loginModal/modalSlice.js";

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(changeStatus("SignIn"));
  };

  return (
    <>
      <div className="hidden lg:flex lg:w-1/2">
        <img
          alt="sign up banner"
          src="/assets/signIn.webp"
          className="h-full w-full"
        />
      </div>
      <div className="w-full lg:w-1/2  h-full flex flex-col justify-center items-center">
        <div className="flex flex-col gap-2">
          <h1 className={formTitle}>{t("getStarted")}</h1>
          <span className={formDescription}>{t("joinNowToAccess")}</span>
        </div>
        <SignUpForm />
        <div className="mt-4 flex items-center gap-[9px]">
          <div className="w-[103px] h-[1px] bg-a020"></div>
          <span className="prose-t14_regular text-a060">
            {t("orSignUpWith")}
          </span>
          <div className="w-[103px] h-[1px] bg-a020"></div>
        </div>
        {/* Google icon */}
        <div className="mt-3">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_106_201)">
              <path
                d="M1.98759 6.83908C2.71415 5.32502 3.74071 4.04533 5.03446 2.98127C6.78759 1.53283 8.79852 0.660954 11.0532 0.393766C13.7016 0.0797037 16.1907 0.581266 18.4923 1.95939C19.0641 2.30158 19.5985 2.69064 20.1095 3.11252C20.236 3.21564 20.222 3.27658 20.1141 3.3797C19.0688 4.42033 18.0235 5.46095 16.9876 6.51095C16.8657 6.63283 16.8001 6.62345 16.6688 6.52502C13.6313 4.18595 9.20165 4.81877 6.93759 7.91252C6.53915 8.45627 6.21571 9.0422 5.99071 9.6797C5.97196 9.73595 5.93446 9.78752 5.90634 9.84377C5.30165 9.38439 4.69227 8.92502 4.09227 8.46095C3.38915 7.92189 2.68602 7.38283 1.98759 6.83908Z"
                fill="#E94335"
              />
              <path
                d="M5.90634 14.2406C6.1079 14.686 6.27665 15.15 6.52977 15.5672C7.59384 17.311 9.11259 18.4313 11.1141 18.8297C12.9188 19.1906 14.6532 18.9469 16.2704 18.0422C16.3266 18.0141 16.3829 17.986 16.4345 17.9578C16.4626 17.986 16.486 18.0188 16.5141 18.0422C17.7235 18.9797 18.9376 19.9172 20.147 20.8547C19.5657 21.4313 18.9048 21.8953 18.2016 22.2985C16.1626 23.461 13.9548 23.9297 11.6298 23.7516C8.7329 23.5266 6.23446 22.3828 4.17196 20.3203C3.26727 19.4156 2.52196 18.3938 1.9829 17.2266C2.48446 16.8422 2.98602 16.4625 3.48759 16.0781C4.29384 15.4641 5.10009 14.8547 5.90634 14.2406Z"
                fill="#24BB76"
              />
              <path
                d="M20.1516 20.8547C18.9423 19.9172 17.7282 18.9797 16.5188 18.0422C16.4907 18.0188 16.4626 17.986 16.4391 17.9578C16.8563 17.6344 17.2829 17.3203 17.6251 16.9078C18.197 16.2235 18.5766 15.45 18.7735 14.5828C18.797 14.475 18.7782 14.4375 18.6704 14.4422C18.6141 14.4469 18.5626 14.4422 18.5063 14.4422C16.5938 14.4422 14.6766 14.4375 12.7641 14.4469C12.5532 14.4469 12.5063 14.3906 12.511 14.1891C12.5204 12.8297 12.5204 11.4703 12.511 10.111C12.511 9.93752 12.5579 9.89064 12.7313 9.89064C16.2376 9.89533 19.7438 9.89533 23.2548 9.89064C23.4048 9.89064 23.4657 9.92814 23.5032 10.0875C23.7891 11.3766 23.7704 12.6703 23.597 13.9735C23.4563 15.0141 23.2032 16.0219 22.8141 16.9969C22.2376 18.436 21.3891 19.6922 20.2735 20.7703C20.2313 20.8031 20.1891 20.8266 20.1516 20.8547Z"
                fill="#4285F3"
              />
              <path
                d="M5.90634 14.2406C5.10009 14.8547 4.29384 15.4641 3.48759 16.0781C2.98602 16.4578 2.48446 16.8422 1.9829 17.2266C1.59384 16.5047 1.34071 15.7406 1.13446 14.9531C0.740711 13.4297 0.679773 11.8828 0.895398 10.3313C1.06415 9.11252 1.41571 7.94064 1.9829 6.84377C2.68602 7.38283 3.38446 7.92658 4.08759 8.46564C4.69227 8.9297 5.29696 9.38908 5.90165 9.84845C5.79852 10.35 5.65321 10.8422 5.60634 11.3578C5.52196 12.2953 5.61102 13.2094 5.87821 14.1094C5.89696 14.1469 5.90165 14.1938 5.90634 14.2406Z"
                fill="#FFCC35"
              />
            </g>
            <defs>
              <clipPath id="clip0_106_201">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <span className="mt-4 prose-t14_regular">
          {t("alreadyHaveAnAccount")}
          <span
            onClick={handleClick}
            className="prose-t14_bold pl-2 cursor-pointer hover:text-a070"
          >
            {t("SigUpSignIn")}
          </span>
        </span>
      </div>
    </>
  );
};

export default SignUp;
