import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./slices/loginModal/modalSlice";
import pendingService from "./slices/pendingService/pendingServiceSlice";
import footerSlice from "./slices/footer/footerSlice";
import popUpSlice from "./slices/popUp/popUpSlice";
import userSLice from "./slices/user/userSlice";

const store = configureStore({
  reducer: {
    modal: modalReducer,
    pendingService,
    footer: footerSlice,
    popUp: popUpSlice,
    user: userSLice,
  },
});

export default store;
