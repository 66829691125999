import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationSchema } from "./validation/validationSchema";
import { setIsShowScrollUpButton } from "../../../redux/slices/footer/footerSlice";
import { useDispatch } from "react-redux";
import { subscribeNewsletter } from "../../../axios/api/footerApi";
import {
  changePopUpStatus,
  setRequestStatus,
} from "../../../redux/slices/popUp/popUpSlice";

const FooterForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { resetForm }) => {
    const responseData = await subscribeNewsletter(values);
    dispatch(setRequestStatus(responseData));
    dispatch(changePopUpStatus(true));
    resetForm();
  };

  const handleInputChange = useCallback(() => {
    if (window.innerWidth < 640) {
      dispatch(setIsShowScrollUpButton(false));
    }
  }, [dispatch]);

  const handleInputBlur = useCallback(() => {
    if (window.innerWidth < 640) {
      dispatch(setIsShowScrollUpButton(true));
    }
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form className="relative">
          <div className="relative z-0">
            <Field
              type="text"
              name="email"
              className="w-[336px] max-w-[359px] h-11 pl-4 lg:pr-[120px] rounded-full focus:outline-none"
              placeholder={t("enterEmailAddress")}
              onInput={handleInputChange}
              onBlur={handleInputBlur}
            />
            <button
              type="submit"
              className="absolute  inset-y-0 right-0 h-full min-w-[110px] px-4 rounded-full prose-t14_regular lg:prose-t16_regular
              bg-[#9747FF] text-white hover:brightness-90"
            >
              {t("subscribe")}
            </button>
          </div>
          {errors.email && touched.email && (
            <div className="absolute top-full left-0">
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 prose-t12_regular text-left mt-1 pl-4"
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default FooterForm;
