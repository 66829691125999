import * as Yup from "yup";
import i18n from "i18next";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(20, () => i18n.t("firstNameMustBe"))
    .required(() => i18n.t("firstNameIsRequired")),
  lastName: Yup.string()
    .max(20, () => i18n.t("lastNameMustBe"))
    .required(() => i18n.t("lastNameIsRequired")),
  email: Yup.string()
    .email(() => i18n.t("invalidEmailAddress"))
    .required(() => i18n.t("emailIsRequired")),
  phoneNumber: Yup.string()
    .matches(/^\+?\d+$/, () => i18n.t("phoneNumberMustContain")) // Allows '+' only at the start, followed by digits
    .min(9, () => i18n.t("phoneNumberMustBeAtLeast"))
    .max(13, () => i18n.t("phoneNumberMustBeAt"))
    .required(() => i18n.t("phoneNumberIsRequired")),
  help: Yup.string()
    .max(300, () => i18n.t("helpMessageMustBe"))
    .required(() => i18n.t("pleaseSpecifyHowWe")),
});
