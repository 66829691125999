import * as Yup from "yup";
import i18n from "i18next";

export const validationSchema = Yup.object().shape({
  companyName: Yup.string()
    .max(30, () => i18n.t("companyNameMustBe"))
    .required(() => i18n.t("companyNameIsRequired")),
  category: Yup.string().max(30, () => i18n.t("CategoryMustBeAt")),
  email: Yup.string()
    .email(() => i18n.t("invalidEmailAddress"))
    .required(() => i18n.t("emailIsRequired")),
  phoneNumber: Yup.string()
    .matches(/^\+?\d+$/, () => i18n.t("phoneNumberMustContain")) // Allows '+' only at the start, followed by digits
    .min(9, () => i18n.t("phoneNumberMustBeAtLeast"))
    .max(13, () => i18n.t("phoneNumberMustBeAt"))
    .required(() => i18n.t("phoneNumberIsRequired")),
  info: Yup.string().required(() => i18n.t("additionalInformationIsRequired")),
});
