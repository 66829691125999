import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { navigateTo } from "../../utils/navigationUtils";
import {
  FaFacebook,
  FaInstagramSquare,
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
} from "react-icons/fa";
import FooterForm from "./footerForm/FooterForm";
import MobileFooter from "./MobileFooter";

const Footer = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const navigate = useNavigate();
  const location = useLocation();

  const routeStyle =
    "prose-t14_regular text-a060 hover:text-a050 cursor-pointer";

  const hideFooter =
    location.pathname === "/partners/partner-list" ||
    location.pathname === "/payment" ||
    location.pathname === "/payment/order";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (hideFooter) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <MobileFooter />
      ) : (
        <div className="bg-a05 w-full flex flex-col">
          <div className="flex flex-row justify-evenly py-[40px] ">
            {/* Social Media */}
            <div className="flex flex-col items-start gap-2">
              <span className="prose-t18_medium">{t("socialMedia")}</span>
              <div className="flex gap-2 text-2xl">
                <FaFacebook />
                <FaInstagramSquare />
              </div>
            </div>
            {/* Company Links */}
            <div className="flex flex-col gap-2">
              <span className="prose-t18_medium">{t("company")}</span>
              <div className="flex flex-col gap-2">
                <span
                  className={routeStyle}
                  onClick={() => navigateTo(navigate, "/services")}
                >
                  {t("services")}
                </span>
                <span
                  className={routeStyle}
                  onClick={() => navigateTo(navigate, "/partners")}
                >
                  {t("partners")}
                </span>
                <span
                  className={routeStyle}
                  onClick={() => navigateTo(navigate, "/about")}
                >
                  {t("aboutUs")}
                </span>
                <span
                  className={routeStyle}
                  onClick={() => navigateTo(navigate, "/contact")}
                >
                  {t("contactUS")}
                </span>
                <span
                  className={routeStyle}
                  onClick={() => navigateTo(navigate, "/privacy-policy")}
                >
                  {t("privacyPolicy")}
                </span>
              </div>
            </div>
            {/* Contact Info */}
            <div className="flex flex-col gap-2">
              <span className="prose-t18_medium">{t("stayInTouch")}</span>
              <div className="flex flex-col gap-2">
                <span className="prose-t16_regular text-[#999999]">
                  sales@myservices.me
                </span>
                <span className="prose-t16_regular text-[#999999]">
                  support@myservices.me
                </span>
                <span className="prose-t16_regular text-[#999999]">
                  +374 (44) 123-456
                </span>
              </div>
            </div>
            {/* Online Payments and Newsletter */}
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-2">
                <span className="prose-t18_medium">{t("onlinePayments")}</span>
                <div className="flex gap-2 text-2xl">
                  <FaCcVisa />
                  <FaCcMastercard />
                  <FaCcPaypal />
                </div>
              </div>
              <div className="flex flex-col gap-2.5">
                <span className="prose-t16_regular text-a080">
                  {t("subscribeToOurNewsletter")}
                </span>
                <FooterForm />
              </div>
            </div>
          </div>
          {/* Copyright section */}
          <div className="h-[64px] border-t-[1px] border-a020 flex justify-center items-center text-center">
            <span className=" prose-t18_regular text-a060">
              {t("copyright")}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
