import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Card from "./desktop/Card";
import OrderHistory from "./desktop/userTable/OrderHistory";
import MobileOrderHistory from "./mobile/MobileOrderHistory";
import { selectIsLoggedIn } from "../../redux/slices/user/userSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import UserProfileForm from "./UserProfileForm";
import { navigateTo } from "../../utils/navigationUtils";

const UserProfile = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigateTo(navigate, "/user-profile");
    } else {
      navigate("*", { replace: false });
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="flex flex-col items-center mt-5 mb-[94px]">
      <div className="max-w-[1194px]">
        {/* Section 1 */}
        <div className="flex flex-col items-start gap-2 px-2">
          <UserProfileForm />
          {/* Safely accessing email */}
          <span className="">{isLoggedIn?.email}</span>
        </div>
        {/* Section 2 */}
        <div className="mt-8">
          <Card />
        </div>
        {/* Section 3 */}
        <div className="flex flex-col mt-8">
          <span className="prose-t18_medium px-2 pb-5">
            {t("orderHistory")}
          </span>
          {isMobile ? <MobileOrderHistory /> : <OrderHistory />}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
