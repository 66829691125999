import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { errorMessageStyle } from "../../ui-elements/formStyles/formStyle";
import { updateUserName } from "../../axios/api/userProfile";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoggedIn,
  setLoginData,
  setUserName,
} from "../../redux/slices/user/userSlice";
import {
  changePopUpStatus,
  setRequestStatus,
} from "../../redux/slices/popUp/popUpSlice";

const UserProfileForm = () => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  const userData = localStorage.getItem("userData");

  const initialUserName = isLoggedIn?.userName || userData?.userName || "User";

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSubmit = async (values) => {
    const data = { ...values, email: isLoggedIn.email };

    dispatch(setUserName(values));
    const responseData = await updateUserName(data);

    if (responseData.status === true) {
      // Retrieve the existing userData from local storage
      const existingUserData =
        JSON.parse(localStorage.getItem("userData")) || {};

      // Add or update the userName in the userData object
      const updatedUserData = {
        ...existingUserData,
        userName: values.userName,
      };
      // Save the updated userData back to local storage
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
    }

    dispatch(changePopUpStatus(true));
    dispatch(setRequestStatus(responseData));
    setIsEditing(false);
  };

  const validationSchema = Yup.object({
    userName: Yup.string()
      .min(3, t("nameMustBeAt"))
      .max(20, t("NameCanBeAtMost"))
      .required(t("userNameIsRequired")),
  });

  return (
    <Formik
      initialValues={{ userName: initialUserName }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="flex items-center gap-2">
          {isEditing ? (
            <div className="flex flex-col">
              <div className="flex gap-2 items-center">
                <Field
                  type="text"
                  name="userName"
                  className="max-w-[220px] md:max-w-[328px] prose-t24_bold md:prose-t36_bold border-b-2 border-gray-300 focus:outline-none focus:border-orange-500"
                  autoFocus
                />
                {/* pen svg */}
                <button type="submit" className="cursor-pointer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#EE5222"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M20 6L9 17l-5-5" />
                  </svg>
                </button>
              </div>
              <div className="min-h-[20px]">
                <ErrorMessage
                  name="userName"
                  component="div"
                  className={errorMessageStyle}
                />
              </div>
            </div>
          ) : (
            <>
              <span className="prose-t24_bold md:prose-t36_bold overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px] sm:max-w-sm">
                {isLoggedIn && isLoggedIn.userName
                  ? isLoggedIn.userName
                  : userData && userData.userName
                  ? userData.userName
                  : "User"}
              </span>
              {/* tick svg */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleEditClick}
                className="cursor-pointer"
              >
                <path
                  d="M5 19H6.425L16.2 9.225L14.775 7.8L5 17.575V19ZM3 21V16.75L16.2 3.575C16.4 3.39167 16.6208 3.25 16.8625 3.15C17.1042 3.05 17.3583 3 17.625 3C17.8917 3 18.15 3.05 18.4 3.15C18.65 3.25 18.8667 3.4 19.05 3.6L20.425 5C20.625 5.18333 20.7708 5.4 20.8625 5.65C20.9542 5.9 21 6.15 21 6.4C21 6.66667 20.9542 6.92083 20.8625 7.1625C20.7708 7.40417 20.625 7.625 20.425 7.825L7.25 21H3ZM15.475 8.525L14.775 7.8L16.2 9.225L15.475 8.525Z"
                  fill="#EE5222"
                />
                <path
                  d="M5 19H6.425L16.2 9.225L15.475 8.525L14.775 7.8L5 17.575V19Z"
                  fill="#EE5222"
                />
              </svg>
            </>
          )}
        </div>
      </Form>
    </Formik>
  );
};

export default UserProfileForm;
