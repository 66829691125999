import React from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  return (
    <div className="flex justify-center gap-4 mt-5">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#EE5222"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          cursor: currentPage === 1 ? "" : "pointer",
        }}
        onClick={() => currentPage !== 1 && handlePageChange(currentPage - 1)}
      >
        <path d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z" />
      </svg>

      {/* Display page numbers */}
      <div className="flex gap-7 ">
        {[...Array(totalPages).keys()].map((pageNumber) => (
          <button
            key={pageNumber + 1}
            className={`${
              currentPage === pageNumber + 1
                ? "active text-white bg-primary border border-primary"
                : "text-a060 border border-a030 hover:bg-a05"
            } h-6 w-6  prose-t16_medium flex items-center justify-center`}
            onClick={() => handlePageChange(pageNumber + 1)}
          >
            {pageNumber + 1}
          </button>
        ))}
      </div>

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#EE5222"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
        }}
        onClick={() =>
          currentPage !== totalPages && handlePageChange(currentPage + 1)
        }
      >
        <path d="M12.6 12L8 7.4L9.4 6L15.4 12L9.4 18L8 16.6L12.6 12Z" />
      </svg>
    </div>
  );
};

export default Pagination;
