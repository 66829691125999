import axios from "axios";

// Variable to store the resetToken
let resetToken = "";

export const setResetToken = (token) => {
  resetToken = token;
};

const requestInterceptor = (config) => {
  // Add the resetToken if it exists
  if (resetToken) {
    config.headers.resetToken = `${resetToken}`;
  }
  return config;
};

const requestErrorInterceptor = (error) => {
  return Promise.reject(error);
};

// Apply interceptors to axios
axios.interceptors.request.use(requestInterceptor, requestErrorInterceptor);

// Export interceptors and functions
export { requestInterceptor, requestErrorInterceptor };
