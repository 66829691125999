// responseInterceptor.js

import axios from "axios";

const responseInterceptor = (response) => {
  // Handle custom response logic here
  return response;
};

const responseErrorInterceptor = (error) => {
  // Handle response errors globally
  if (error.response && error.response.status === 401) {
    // For example, you can redirect to the login page or logout
    // window.location.href = '/login';
  }
  return Promise.reject(error);
};

// Apply interceptors to axios
axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

export { responseInterceptor, responseErrorInterceptor };
