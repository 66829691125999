import React from "react";
import { useTranslation } from "react-i18next";

const TrustBy = () => {
  const { t } = useTranslation();

  const logos = [
    { id: 1, url: "logo1.png" },
    { id: 2, url: "logo2.png" },
    { id: 3, url: "logo3.png" },
    { id: 4, url: "logo4.png" },
    { id: 5, url: "logo5.png" },
    { id: 6, url: "logo6.png" },
  ];

  return (
    <div className="flex flex-col gap-5 items-center ">
      <span className="prose-t20_medium">{t("trustBy")} </span>
      <div className="flex flex-wrap justify-center md:flex-row gap-4 ">
        {logos.map((logo) => (
          <span
            key={logo.id}
            className=" w-[164px] lg:w-[184px] h-[92px] bg-a035 flex items-center justify-center"
          >
            <img src={logo.url} alt={`Logo ${logo.id}`} />
          </span>
        ))}
      </div>
    </div>
  );
};

export default TrustBy;
