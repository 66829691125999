import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Partners from "../pages/Partners";
import Services from "../pages/Services";
import NotFound from "../pages/NotFound";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import PartnerList from "../components/partners/PartnerList";
import OnePartner from "../components/partners/OnePartner";
import Payment from "../pages/Payment";
import Order from "../components/payment/Order";
import UserProfilePage from "../pages/UserProfilePage";

export const routes = [
  // English routes
  {
    path: "/en",
    element: <Home />,
  },
  {
    path: "/en/about",
    element: <About />,
  },
  {
    path: "/en/contact",
    element: <Contact />,
  },
  {
    path: "/en/partners/partner-list",
    element: <PartnerList />,
  },
  {
    path: "/en/partners/partner",
    element: <OnePartner />,
  },
  {
    path: "/en/partners",
    element: <Partners />,
  },
  {
    path: "/en/services",
    element: <Services />,
  },
  {
    path: "/en/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/en/payment",
    element: <Payment />,
  },
  {
    path: "/en/payment/order",
    element: <Order />,
  },
  {
    path: "/en/user-profile",
    element: <UserProfilePage />,
  },

  // Russian routes
  {
    path: "/ru",
    element: <Home />,
  },
  {
    path: "/ru/about",
    element: <About />,
  },
  {
    path: "/ru/contact",
    element: <Contact />,
  },
  {
    path: "/ru/partners/partner-list",
    element: <PartnerList />,
  },
  {
    path: "/ru/partners/partner",
    element: <OnePartner />,
  },
  {
    path: "/ru/partners",
    element: <Partners />,
  },
  {
    path: "/ru/services",
    element: <Services />,
  },
  {
    path: "/ru/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/ru/payment",
    element: <Payment />,
  },
  {
    path: "/ru/payment/order",
    element: <Order />,
  },
  {
    path: "/ru/user-profile",
    element: <UserProfilePage />,
  },

  // Armenian routes
  {
    path: "/am",
    element: <Home />,
  },
  {
    path: "/am/about",
    element: <About />,
  },
  {
    path: "/am/contact",
    element: <Contact />,
  },
  {
    path: "/am/partners/partner-list",
    element: <PartnerList />,
  },
  {
    path: "/am/partners/partner",
    element: <OnePartner />,
  },
  {
    path: "/am/partners",
    element: <Partners />,
  },
  {
    path: "/am/services",
    element: <Services />,
  },
  {
    path: "/am/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/am/payment",
    element: <Payment />,
  },
  {
    path: "/am/payment/order",
    element: <Order />,
  },
  {
    path: "/am/user-profile",
    element: <UserProfilePage />,
  },

  // Fallback route
  {
    path: "*",
    element: <NotFound />,
  },
];
