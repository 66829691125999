import React from "react";
import SignInForm from "./Form/SignInForm";

const SignIn = () => {
  return (
    <>
      <div className="hidden lg:flex lg:w-1/2">
        <img
          alt="sign In banner"
          // If you navigate directly to the user-profile page without  log  in, this method will help you gain direct access to image.
          src={`${window.location.origin}/assets/signIn.webp`}
          className="w-full h-full"
        />
      </div>
      <div className="w-full lg:w-1/2">
        <div className="flex flex-col justify-center items-center h-full">
          <SignInForm />
        </div>
      </div>
    </>
  );
};

export default SignIn;
