import axiosInstance from "../axiosInstance";

export const createUser = async (data) => {
  try {
    const response = await axiosInstance.post("/register", data);
    return response.data;
  } catch (error) {
    console.error("Error sending sign-up data", error);
    throw error;
  }
};

export const login = async (data) => {
  try {
    const response = await axiosInstance.post("/login", data);
    return response.data;
  } catch (error) {
    console.error("Error sending sign-up data", error);
    throw error;
  }
};

export const forgotPassword = async (data) => {
  try {
    const response = await axiosInstance.post("/forgot_password", data);
    return response.data;
  } catch (error) {
    console.error("Error sending sign-up data", error);
    throw error;
  }
};

export const resetPassword = async (data, token) => {
  try {
    const response = await axiosInstance.post("/reset_password", data, {
      headers: {
        Authorization: `Bearer ${token}`, // Set the resetToken in the headers for this request
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending reset password data", error);
    throw error;
  }
};

export const verify = async (data) => {
  try {
    const response = await axiosInstance.post("/verify", data);
    return response.data;
  } catch (error) {
    console.error("Error sending sign-up data", error);
    throw error;
  }
};
