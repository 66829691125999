import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StarRating from "../../helpers/StarRating";
import Pagination from "./partnersCard/Pagination";
import { motion } from "framer-motion";

const UserComments = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);

  const mockData = [
    {
      id: 1,
      name: "Lukxrust",
      star: "4",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 2,
      name: "Lukxrust",
      star: "3",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 3,
      name: "Lukxrust",
      star: "2",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 4,
      name: "Lukxrust",
      star: "1",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 5,
      name: "Lukxrust",
      star: "5",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 6,
      name: "Lukxrust",
      star: "4",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 7,
      name: "Lukxrust",
      star: "3",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
    {
      id: 8,
      name: "Lukxrust",
      star: "4",
      date: "16.02.2024",
      comment:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
    },
  ];

  const itemsPerPage = 3; // Number of items per page
  const totalItems = mockData.length; // Total number of items
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginateData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return mockData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = paginateData();

  return (
    <motion.div
      className="flex flex-col items-center mt-[60px]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div>
        <h4 className="prose-t24_bold text-a070">{t("userComments")}</h4>
      </div>
      <div className="flex flex-col items-center max-w-[790px] mt-9 px-4 md:px-0">
        {paginatedData.map((item) => {
          return (
            <motion.div
              key={item.id}
              className="flex flex-col gap-1.5 mt-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <span className="prose-t16_regular text-a070">{item.name}</span>
              <div className="flex items-center gap-3">
                <span className="flex gap-2">
                  <StarRating count={item.star} />
                </span>
                <span className="prose-t12_regular text-a050">{item.date}</span>
              </div>
              <p className="prose-t16_regular text-a070">{item.comment}</p>
              <div className="h-[1px] bg-[#D9D9D9] mt-3.5"> </div>
            </motion.div>
          );
        })}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </motion.div>
  );
};

export default UserComments;
