import axiosInstance from "../axiosInstance";

export const becomeAPartner = async (data) => {
  try {
    const response = await axiosInstance.post("/become_partner", data);
    return response.data;
  } catch (error) {
    console.error("Error sending sign-up data", error);
    throw error;
  }
};
