import React from "react";
import { useTranslation } from "react-i18next";
import OrderForm from "./OrderForm";

const Order = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col items-center ">
      <div className="max-w-[588px] py-[65px]">
        <div className="flex flex-col items-start gap-2">
          <h2 className="prose-t24_bold">{t("almostFinished")}</h2>
          <span className="text-a070">{t("almostThereConfirmYour")}</span>
        </div>
        <OrderForm />
      </div>
    </div>
  );
};

export default Order;
