import React from "react";
import { useTranslation } from "react-i18next";

const Benefits = () => {
  const { t } = useTranslation();

  const mockdata = [
    {
      title: t("timeSaving"),
      desc: t("eliminatingTheNeedFor"),
      url: "/assets/TimeSaving.png",
    },
    {
      title: t("userFeedback"),
      desc: t("makeInformedDecisionsBased"),
      url: "/assets/userFeedback.png",
    },
    {
      title: t("accessToRangeOf"),
      desc: t("findEverythingFromHousehold"),
      url: "/assets/accessToRangeOf.png",
    },
    {
      title: t("24/7availability"),
      desc: t("bookServicesAtAnyTime"),
      url: "/assets/247availability.png",
    },
  ];
  return (
    <div className=" max-w-[790px] flex justify-center  flex-wrap gap-[18px]">
      {mockdata.map((item) => (
        <div
          key={item.title}
          className="w-[386px] min-h-[88px] flex items-center gap-2.5 px-3 py-2.5 rounded-xl border shadow-md"
        >
          <img alt="benefit" src={item.url} className="w-10 h-10" />
          <div className="flex flex-col gap-1">
            <h6 className="prose-t16_medium">{item.title}</h6>
            <p className="prose-t14_regular text-a060">{item.desc}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Benefits;
