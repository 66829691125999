import React from "react";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectIsShowScrollUpButton } from "../../redux/slices/footer/footerSlice";

const ScrollToTopButton = ({ onClick }) => {
  const isShow = useSelector(selectIsShowScrollUpButton);

  return (
    isShow && (
      <motion.div
        className="fixed bottom-16 sm:bottom-10 right-10 z-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="w-[60px] h-[60px] bg-white shadow-md	 rounded-full cursor-pointer flex items-center justify-center"
          onClick={onClick}
        >
          <motion.div
            className="flex items-center justify-center"
            whileHover={{ translateY: -10 }}
            transition={{ duration: 0.3 }}
          >
            <svg
              width="16"
              height="28"
              viewBox="0 0 16 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.05001 8.05002C1.14297 8.14375 1.25357 8.21815 1.37543 8.26892C1.49729 8.31968 1.628 8.34582 1.76001 8.34582C1.89202 8.34582 2.02273 8.31968 2.14459 8.26892C2.26644 8.21815 2.37705 8.14375 2.47001 8.05002L7.07001 3.45002V27C7.07001 27.2652 7.17537 27.5196 7.3629 27.7071C7.55044 27.8947 7.80479 28 8.07001 28C8.33522 28 8.58958 27.8947 8.77712 27.7071C8.96465 27.5196 9.07001 27.2652 9.07001 27V3.48002L13.64 8.05002C13.8313 8.21385 14.0774 8.29945 14.3291 8.28973C14.5807 8.28001 14.8195 8.17568 14.9976 7.99759C15.1757 7.81949 15.28 7.58075 15.2897 7.32908C15.2994 7.0774 15.2138 6.83133 15.05 6.64002L8.69001 0.270023C8.52134 0.102093 8.29302 0.0078125 8.05501 0.0078125C7.817 0.0078125 7.58868 0.102093 7.42001 0.270023L1.05001 6.64002C0.863758 6.82739 0.759216 7.08084 0.759216 7.34502C0.759216 7.60921 0.863758 7.86266 1.05001 8.05002Z"
                fill="#EE5222"
              />
            </svg>
          </motion.div>
        </div>
      </motion.div>
    )
  );
};

export default ScrollToTopButton;
