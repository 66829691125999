import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../../utils/navigationUtils";
import { motion, AnimatePresence } from "framer-motion";
import { secondaryFixed } from "../../ui-elements/button";
import { useLocation, useNavigate } from "react-router-dom";
import StarRating from "../../helpers/StarRating";

const ServiceList = ({ data, seeMore }) => {
  const { t } = useTranslation();

  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const topRef = useRef(null);

  const handleNavigate = () => {
    if (location.pathname.endsWith("/partners/partner")) {
      navigateTo(navigate, "/payment");
    } else {
      navigateTo(navigate, "/partners/partner-list");
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const itemVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const handleClick = () => {
    setShowAll(!showAll);
    if (showAll) {
      window.scrollTo({ top: 1000, behavior: "smooth" });
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        ref={topRef}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="flex flex-col px-2 lg:px-0 gap-[18px]"
      >
        <div className="max-w-[1250px] flex flex-wrap justify-center gap-[18px]">
          {data.slice(0, showAll ? data.length : 8).map((item) => (
            <motion.div
              key={item.id}
              variants={itemVariants}
              className={
                "cursor-pointer w-[164px] md:w-[285px] border border-a015 bg-white shadow-md rounded-xl object-contain"
              }
              onClick={handleNavigate}
            >
              <img
                alt={item.name}
                src={item.url}
                className="h-[163px] md:h-[223px] rounded-xl object-cover"
              />
              {item.stars ? (
                <span className="flex justify-center gap-2 pt-2">
                  <StarRating count={item.stars} />
                </span>
              ) : null}
              <span
                className={`${
                  item.stars ? "mb-1.5 mt-1.5" : "py-5"
                } prose-t18_bold md:prose-t24_medium flex justify-center `}
              >
                {item.name}
              </span>
            </motion.div>
          ))}
        </div>
        {seeMore ? (
          <motion.div className="flex justify-center pt-8">
            {data.length > 8 && (
              <button className={secondaryFixed} onClick={handleClick}>
                {showAll ? t("showLess") : t("showMore")}
              </button>
            )}
          </motion.div>
        ) : null}
      </motion.div>
    </AnimatePresence>
  );
};

export default ServiceList;
