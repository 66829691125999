import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { Circles } from "react-loader-spinner";

const GlobalRouteMiddleware = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    if (location.pathname === "/" && !location.search) {
      const defaultLanguage = "am"; // Default language
      navigate(`/${defaultLanguage}`, { replace: true });
    } else {
      setLoading(false); // Set loading to false once path is valid
    }
  }, [location.pathname, location.search, navigate]);

  const element = useRoutes(routes);

  // Return a spinner while loading
  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Circles
          height="120"
          width="120"
          color="#ee5222"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  // Return the routes after loading is complete
  return element;
};

export default GlobalRouteMiddleware;
