import React from "react";
import { useTranslation } from "react-i18next";

const Card = () => {
  const { t } = useTranslation();

  const mockData = [
    {
      id: 1,
      title: "Finished",
      count: "24",
      icon: (
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 33C15.925 33 13.975 32.6063 12.15 31.8188C10.325 31.0313 8.7375 29.9625 7.3875 28.6125C6.0375 27.2625 4.96875 25.675 4.18125 23.85C3.39375 22.025 3 20.075 3 18C3 15.925 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.0375 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.96875 12.15 4.18125C13.975 3.39375 15.925 3 18 3C19.625 3 21.1625 3.2375 22.6125 3.7125C24.0625 4.1875 25.4 4.85 26.625 5.7L24.45 7.9125C23.5 7.3125 22.4875 6.84375 21.4125 6.50625C20.3375 6.16875 19.2 6 18 6C14.675 6 11.8438 7.16875 9.50625 9.50625C7.16875 11.8438 6 14.675 6 18C6 21.325 7.16875 24.1562 9.50625 26.4938C11.8438 28.8313 14.675 30 18 30C21.325 30 24.1562 28.8313 26.4938 26.4938C28.8313 24.1562 30 21.325 30 18C30 17.55 29.975 17.1 29.925 16.65C29.875 16.2 29.8 15.7625 29.7 15.3375L32.1375 12.9C32.4125 13.7 32.625 14.525 32.775 15.375C32.925 16.225 33 17.1 33 18C33 20.075 32.6063 22.025 31.8188 23.85C31.0313 25.675 29.9625 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0313 23.85 31.8188C22.025 32.6063 20.075 33 18 33ZM15.9 24.9L9.525 18.525L11.625 16.425L15.9 20.7L30.9 5.6625L33 7.7625L15.9 24.9Z"
            fill="#34B349"
          />
        </svg>
      ),
    },
    {
      id: 2,
      title: "Canceled",
      count: "1",
      icon: (
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.6 25.5L18 20.1L23.4 25.5L25.5 23.4L20.1 18L25.5 12.6L23.4 10.5L18 15.9L12.6 10.5L10.5 12.6L15.9 18L10.5 23.4L12.6 25.5ZM18 33C15.925 33 13.975 32.6063 12.15 31.8188C10.325 31.0313 8.7375 29.9625 7.3875 28.6125C6.0375 27.2625 4.96875 25.675 4.18125 23.85C3.39375 22.025 3 20.075 3 18C3 15.925 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.0375 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.96875 12.15 4.18125C13.975 3.39375 15.925 3 18 3C20.075 3 22.025 3.39375 23.85 4.18125C25.675 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0313 10.325 31.8188 12.15C32.6063 13.975 33 15.925 33 18C33 20.075 32.6063 22.025 31.8188 23.85C31.0313 25.675 29.9625 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0313 23.85 31.8188C22.025 32.6063 20.075 33 18 33ZM18 30C21.35 30 24.1875 28.8375 26.5125 26.5125C28.8375 24.1875 30 21.35 30 18C30 14.65 28.8375 11.8125 26.5125 9.4875C24.1875 7.1625 21.35 6 18 6C14.65 6 11.8125 7.1625 9.4875 9.4875C7.1625 11.8125 6 14.65 6 18C6 21.35 7.1625 24.1875 9.4875 26.5125C11.8125 28.8375 14.65 30 18 30Z"
            fill="#F02934"
          />
        </svg>
      ),
    },
    {
      id: 3,
      title: "Pending",
      count: "3",
      icon: (
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.95 25.05L25.05 22.95L19.5 17.4V10.5H16.5V18.6L22.95 25.05ZM18 33C15.925 33 13.975 32.6063 12.15 31.8188C10.325 31.0313 8.7375 29.9625 7.3875 28.6125C6.0375 27.2625 4.96875 25.675 4.18125 23.85C3.39375 22.025 3 20.075 3 18C3 15.925 3.39375 13.975 4.18125 12.15C4.96875 10.325 6.0375 8.7375 7.3875 7.3875C8.7375 6.0375 10.325 4.96875 12.15 4.18125C13.975 3.39375 15.925 3 18 3C20.075 3 22.025 3.39375 23.85 4.18125C25.675 4.96875 27.2625 6.0375 28.6125 7.3875C29.9625 8.7375 31.0313 10.325 31.8188 12.15C32.6063 13.975 33 15.925 33 18C33 20.075 32.6063 22.025 31.8188 23.85C31.0313 25.675 29.9625 27.2625 28.6125 28.6125C27.2625 29.9625 25.675 31.0313 23.85 31.8188C22.025 32.6063 20.075 33 18 33ZM18 30C21.325 30 24.1562 28.8313 26.4938 26.4938C28.8313 24.1562 30 21.325 30 18C30 14.675 28.8313 11.8438 26.4938 9.50625C24.1562 7.16875 21.325 6 18 6C14.675 6 11.8438 7.16875 9.50625 9.50625C7.16875 11.8438 6 14.675 6 18C6 21.325 7.16875 24.1562 9.50625 26.4938C11.8438 28.8313 14.675 30 18 30Z"
            fill="#9747FF"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-5">
      <span className="prose-t18_medium px-2">{t("orderStatusDetails")}</span>
      <div className="flex flex-col xl:flex-row gap-[18px]">
        {mockData.map((item) => {
          const getStatusColor = (title) => {
            switch (title) {
              case "Finished":
                return "#34B349";
              case "Canceled":
                return "#F02934";
              case "Pending":
                return "#9747FF";
              default:
                return "#000000";
            }
          };

          return (
            <div
              key={item.id}
              className="h-[112px] w-[336px] md:w-[386px] flex items-center justify-between border border-a010 shadow-md rounded-lg px-3"
            >
              <div className="flex items-center gap-3">
                <div
                  className="w-0.5 h-[88px]"
                  style={{ backgroundColor: getStatusColor(item.title) }}
                ></div>
                <div className="flex flex-col gap-8 py-3">
                  <span className="prose-t14_medium">{item.title}</span>
                  <span className="prose-t26_bold">{item.count}</span>
                </div>
              </div>
              <div className="w-[56px] h-[56px] flex items-center justify-center bg-a03 border border-a05 rounded-xl">
                {item.icon}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Card;
