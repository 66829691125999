import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GlobalRouteMiddleware from "../src/router/GlobalRouteMiddleware";
import { navigateTo } from "./utils/navigationUtils";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import LoginModal from "./components/login/LoginModal";
import { AnimatePresence } from "framer-motion";
import PendingServices from "./components/pendingService/PendingServices";
import { verify } from "./axios/api/loginApi";
import { useDispatch, useSelector } from "react-redux";
import {
  changePopUpStatus,
  selectIsOpenModal,
  setRequestStatus,
} from "./redux/slices/popUp/popUpSlice";
import PopUpWindow from "./components/pop-up/PopUpWindow";
import { selectIsLoggedIn, setLoginData } from "./redux/slices/user/userSlice";
import { changeStatus, closeModal } from "./redux/slices/loginModal/modalSlice";

function App() {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const isOpenPopUp = useSelector(selectIsOpenModal);
  const [fontClass, setFontClass] = useState("font-roboto");
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  //  user-profile  checker
  useEffect(() => {
    if (isLoggedIn && location.pathname.endsWith("/user-profile/*")) {
      navigateTo(navigate, "/user-profile");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  useEffect(() => {
    try {
      const storedUserData = localStorage.getItem("userData");
      if (storedUserData) {
        const parsedUserData = JSON.parse(storedUserData);
        dispatch(setLoginData(parsedUserData));
      }
    } catch (error) {
      console.error("", error);
    }
  }, [dispatch]);

  useEffect(() => {
    const checkVerification = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const verifyToken = queryParams.get("verify");
        const forgotPassword = queryParams.get("forgotPassword");

        if (verifyToken) {
          const checkUser = await verify({ verify: verifyToken });

          // auto login
          if (checkUser?.status === true) {
            localStorage.setItem("userData", JSON.stringify(checkUser));
            dispatch(setRequestStatus(checkUser));
          }

          // open pop up
          dispatch(setLoginData(checkUser));
          dispatch(changePopUpStatus(true));

          // Remove the "verify" token from the URL
          queryParams.delete("verify");
          window.history.replaceState(
            null,
            "",
            queryParams.toString()
              ? "?" + queryParams.toString()
              : window.location.pathname
          );
        } else if (forgotPassword) {
          localStorage.setItem("token", forgotPassword);
          dispatch(changeStatus("ResetPassword"));
          dispatch(closeModal(true));
        }
      } catch (error) {
        console.error("Verification error:", error);
      }
    };

    checkVerification();
  }, [location.search, dispatch]);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage === "am") {
      setFontClass("font-mardato");
    } else {
      setFontClass("font-roboto");
    }
  }, []);

  return (
    <div className={`App  ${fontClass}`}>
      <Header />
      <LoginModal />
      {/* {isLoggedIn ? (
        <AnimatePresence>
          <PendingServices />
        </AnimatePresence>
      ) : null} */}
      <GlobalRouteMiddleware /> {/* Render routes with middleware */}
      {isOpenPopUp && <PopUpWindow />}
      <Footer />
    </div>
  );
}

export default App;
