import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoMenu, IoClose } from "react-icons/io5";
import LanguageMenu from "../languageMenu/LanguageMenu";
import ButtonGroup from "./ButtonGroup";
import ReactModal from "react-modal";
import "./mobileHeader.css";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStatus,
  openModal,
} from "../../redux/slices/loginModal/modalSlice";
import { selectIsLoggedIn } from "../../redux/slices/user/userSlice";

ReactModal.setAppElement("#root");

const MobileHeader = ({
  menuOpen,
  setMenuOpen,
  navbar,
  activeRoute,
  handleNavigation,
  toggleSignInModal,
  toggleSignUpModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const handleClick = () => {
    dispatch(openModal(true));
    dispatch(changeStatus("SignIn"));
    setMenuOpen(false);
  };

  const mobileMenuOpen = () => {
    document.body.classList.add("isOpenModal"); // Add class to prevent scrolling
    setMenuOpen(!menuOpen);
  };

  const mobileMenuClose = () => {
    document.body.classList.remove("isOpenModal");
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    return () => {
      // Cleanup when the component unmounts
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <>
      <nav className="bg-white min-h-[64px] flex items-center justify-between shadow-md px-4 py-4 sticky top-0 w-full z-30">
        <div className="w-full flex items-center justify-between">
          {/* Burger menu icon */}
          <div className="flex items-center gap-4">
            <button
              onClick={mobileMenuOpen}
              className="text-gray-800 focus:outline-none"
            >
              {menuOpen ? (
                <IoClose className="w-6 h-6" />
              ) : (
                <IoMenu className="w-6 h-6" />
              )}
            </button>

            {/* Logo */}
            <div>
              <img
                src="/assets/header-logo.png"
                alt="my service"
                onClick={() => handleNavigation("")}
                className="w-[116px] h-[32px] cursor-pointer"
              />
            </div>
          </div>
          <ButtonGroup
            toggleSignInModal={toggleSignInModal}
            toggleSignUpModal={toggleSignUpModal}
          />
        </div>
      </nav>
      {/* Burger menu container */}

      <ReactModal
        isOpen={menuOpen}
        onRequestClose={mobileMenuClose}
        className="content"
        overlayClassName="overlay"
      >
        <div className="h-full bg-white flex flex-col justify-evenly items-center z-50">
          <div className="flex w-full p-4">
            <button
              onClick={mobileMenuClose}
              className="text-gray-800 focus:outline-none flex gap-4 prose-t16_regular"
            >
              <IoClose className="w-6 h-6" />
              {t("menu")}
            </button>
          </div>
          <div className="flex flex-col items-center w-full">
            {navbar.map((item, index) => (
              <div key={item.name} className="w-full">
                <button
                  onClick={() => handleNavigation(item.route)}
                  className={`w-full text-left px-6 py-4 prose-t14_regular text-a050 ${
                    activeRoute === item.route ? "bg-gray-200" : ""
                  }`}
                >
                  {item.name}
                </button>
                {index < navbar.length - 1 && (
                  <div className="h-0.5 bg-[#D9D9D9] w-full"></div>
                )}
              </div>
            ))}
            {!isLoggedIn ? (
              <button
                className="mt-5  border border-a015 shadow-md py-3 w-[328px] rounded-3xl "
                onClick={handleClick}
              >
                {t("login")}
              </button>
            ) : null}
          </div>
          <div className="mt-auto pb-14 sm:pt-4 sm:pb-2 ">
            <LanguageMenu />
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default MobileHeader;
