import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const privacyPolicyData = [
    {
      id: 1,
      title: t("introduction"),
      descp: t("welcomeToMyServiceAm"),
    },
    {
      id: 2,
      title: t("informationWeCollect"),
      descp: `${t("personalInformationWhenYou")}\n${t("usageDataWeMay")}\n${t(
        "cookiesAndTrackingTechnologies"
      )}`,
    },
    {
      id: 3,
      title: t("howWeUseYour"),
      descp: `${t("toProvideServicesWe")}\n${t("toImproveOurWebsite")}\n${t(
        "marketingAndCommunicationWith"
      )}\n${t("legalComplianceWeMay")}`,
    },
    {
      id: 4,
      title: t("informationSharingAndDisclosure"),
      descp: `${t("serviceProvidersWeMay")}\n${t(
        "businessTransfersInThe"
      )}\n${t("legalRequirementsWeMay")}`,
    },
    {
      id: 5,
      title: t("security"),
      descp: t("weTakeReasonableMeasures"),
    },
    {
      id: 6,
      title: t("yourChoices"),
      descp: `${t("accessAndUpdateYou")}\n${t("optOutYouCan")}\n${t(
        "cookiesYouCanSet"
      )}`,
    },
    {
      id: 7,
      title: t("childrenPrivacy"),
      descp: t("ourWebsiteIsNot"),
    },
    {
      id: 8,
      title: t("contactUS1"),
      descp: `${t("ifYouHaveAny")}\n${t("privacyEmail")}\n${t(
        "privacyPhone"
      )}\n${t("privacyAddress")}`,
    },
    {
      id: 9,
      title: "",
      descp: t("thankYouForUsing"),
    },
  ];

  const processDescription = (description) => {
    return description.split("\n");
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <div className="relative w-full z-0">
          <img
            alt="partner"
            src="/assets/privacyPolicy.webp"
            className="h-[338px] w-full brightness-50 object-cover"
          />
          <div className="absolute inset-0 flex flex-col gap-3.5 justify-center items-center">
            <h2 className="prose-t40_bold text-white">{t("privacy&Policy")}</h2>
            <p className="prose-t16_regular text-white max-w-[362px] text-center">
              Effective May 1, 2024
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-10 mt-8 mb-[64px] md:mt-0 md:mb:0 md:py-11">
        {privacyPolicyData.map((item) => (
          <div
            key={item.id}
            className="max-w-[652px] flex flex-col md:items-center gap-5 px-4 md:px-0"
          >
            <h4 className="prose-t26_bold">{item.title}</h4>
            {processDescription(item.descp).map((paragraph, index) => (
              <p key={index} className="text-a070 prose-t16_regular ">
                {paragraph}
                {index < processDescription(item.descp).length - 1 && <br />}
              </p>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
