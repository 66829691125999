export const flatButton =
  "prose-t16_medium py-3 h-[48px] hover:rounded-3xl hover:bg-a05";

export const secondaryFixed =
  "prose-t16_regular w-[164px] h-[48px] rounded-3xl bg-white border-2 border-a015  hover:bg-a05";

export const primaryFIxed =
  "w-[164px] h-[48px] text-white bg-primary rounded-3xl prose-t16_regular hover:filter hover:brightness-90";

export const primary =
  " h-[48px] text-white bg-primary rounded-3xl prose-t16_regular  hover:filter hover:brightness-90";
