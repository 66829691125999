import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPlus, FaMinus } from "react-icons/fa6";
import { motion, AnimatePresence } from "framer-motion";

const Faq = () => {
  const { t } = useTranslation();
  const [showAnswer, setShowAnswer] = useState({});

  const mockData = [
    {
      id: 1,
      question: t("howDoIBook"),
      answer: t("bookingAService"),
    },
    {
      id: 2,
      question: t("areTheServiceProviders"),
      answer: t("yesAllService"),
    },
    {
      id: 3,
      question: t("howCanIPay"),
      answer: t("youCanPaySecurely"),
    },
    {
      id: 4,
      question: t("canICancelOr"),
      answer: t("yesYouCanCancel"),
    },
    {
      id: 5,
      question: t("howDoILeave"),
      answer: t("afterCompletingYourService"),
    },
    {
      id: 6,
      question: t("whatShouldIDo"),
      answer: t("ifYouAreNot"),
    },
    {
      id: 7,
      question: t("howDoIBecome"),
      answer: t("youCanApplyThrough"),
    },
    {
      id: 8,
      question: t("isMyPersonalInformation"),
      answer: t("yesWePrioritizeYour"),
    },
  ];

  const handleClick = (id) => {
    setShowAnswer((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="flex flex-col gap-8 px-2">
      <div className="flex lg:items-center text-center gap-2 ">
        <span className="sm:flex  sm:justify-center gap-2 w-full lg:justify-start prose-t32_regular lg:prose-t40_bold">
          {t("frequentlyAskedQuestions")}
          <span className="text-primary prose-t32_bold lg:prose-t40_bold ml-1.5 sm:ml-0">
            {t("faq")}
          </span>
        </span>
      </div>
      <div className="flex flex-col max-w-[800px]">
        {mockData.map((faq) => (
          <div
            key={faq.id}
            className="flex flex-col gap-4 border-b  border-[#E5E5E5] mb-2.5 cursor-pointer px-2 lg:px-0 lg:w-[800px]"
            onClick={() => handleClick(faq.id)}
          >
            <div className="flex justify-between items-center min-h-[60px]   ">
              <p className=" prose-t20_medium lg:prose-t24_medium flex items-center text-black">
                {faq.question}
              </p>
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                {showAnswer[faq.id] ? <FaMinus /> : <FaPlus />}
              </motion.button>
            </div>
            <AnimatePresence>
              {showAnswer[faq.id] && (
                <motion.p
                  key={`${faq.id}-answer`}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  className="prose-t18_regular text-a070 pb-2.5 lg:w-[800px]"
                >
                  {faq.answer}
                </motion.p>
              )}
            </AnimatePresence>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
