import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  errorMessageStyle,
  formInput,
} from "../../ui-elements/formStyles/formStyle";
import { primaryFIxed } from "../../ui-elements/button";
import { FaCcMastercard, FaCcPaypal } from "react-icons/fa";
import { validationSchema } from "./validation/validationSchema";
import ru from "date-fns/locale/ru"; // Russian
import am from "date-fns/locale/hy"; // Armenian

// Register the locales
registerLocale("ru", ru);
registerLocale("am", am);

const OrderForm = () => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [paymentMethod, setPaymentMethod] = useState("");

  useEffect(() => {
    const currentLocale =
      i18n.language === "ru" ? "ru" : i18n.language === "am" ? "am" : "en";
    setDefaultLocale(currentLocale);
  }, [i18n.language]);

  const handleSubmit = (values) => {
    console.log(values);
  };

  const today = new Date();
  const placeholderText = today.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <Formik
      initialValues={{
        date: startDate,
        address: "",
        phoneNumber: "",
        paymentMethod: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form className="flex flex-col gap-8 mt-6">
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
                setFieldValue("date", date);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy HH:mm"
              timeCaption="Time"
              className={`${formInput} text-a040 focus:text-a080`}
              placeholderText={placeholderText}
              locale={i18n.language}
              // Disable manual input
              customInput={<input className={`${formInput} cursor-pointer`} />}
            />
            <ErrorMessage
              name="date"
              component="div"
              className={errorMessageStyle}
            />
          </div>

          <div>
            <Field
              name="address"
              placeholder={t("enterAddress")}
              type="text"
              className={formInput}
            />
            <ErrorMessage
              name="address"
              component="div"
              className={errorMessageStyle}
            />
          </div>

          <div>
            <Field
              name="phoneNumber"
              placeholder={t("phoneNumber")}
              type="text"
              className={formInput}
            />
            <ErrorMessage
              name="phoneNumber"
              component="div"
              className={errorMessageStyle}
            />
          </div>

          <div>
            <div role="group" aria-labelledby="payment-method">
              <label className="mr-4">
                <Field
                  type="radio"
                  name="paymentMethod"
                  value="cash"
                  className="mr-2"
                  onClick={() => setPaymentMethod("cash")}
                />
                {t("Cash")}
              </label>
              <label className="mr-4">
                <Field
                  type="radio"
                  name="paymentMethod"
                  value="online"
                  className="mr-2"
                  onClick={() => setPaymentMethod("online")}
                />
                {t("Online")}
              </label>
            </div>
            <ErrorMessage
              name="paymentMethod"
              component="div"
              className={errorMessageStyle}
            />

            {paymentMethod === "online" && (
              <div className="flex mt-4 space-x-4">
                <FaCcMastercard className="w-8 h-8" />
                <FaCcPaypal className="w-8 h-8" />
              </div>
            )}
          </div>
          {/* Order Description */}
          <div className="flex flex-col gap-5 items-center">
            <div className="flex flex-col gap-2">
              <h2 className="prose-t18_medium">{t("myOrder")}</h2>
              <div className="flex gap-1.5">
                <span>barev</span>
                <span>barev</span>
              </div>
            </div>
            <div className="flex  items-center  gap-3">
              <span className="prose-t16_medium">Total</span>
              <span className="prose-t20_bold">26 $</span>
            </div>
          </div>

          <button type="submit" className={primaryFIxed}>
            {t("Submit")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default OrderForm;
