import React from "react";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-grow items-center justify-center bg-gray-50">
      <div className="rounded-lg bg-white p-8 text-center shadow-2xl">
        <h1 className="mb-4 prose-t40_bold">404</h1>
        <p className="prose-t18_regular text-a070">{t("oopsThePageYou")} </p>
        <a
          href="/"
          className="mt-4 inline-block rounded-3xl bg-primary px-4 py-2 font-semibold text-white hover:brightness-90"
        >
          {t("goBackToHome")}
        </a>
      </div>
    </div>
  );
};

export default NotFound;
