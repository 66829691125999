import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: null,
  },
  reducers: {
    setLoginData: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setUserName: (state, action) => {
      state.isLoggedIn = { ...state.isLoggedIn, ...action.payload };
    },
  },
});

export const { setLoginData, setUserName } = userSlice.actions;

export const selectIsLoggedIn = (state) => state.user.isLoggedIn;

export default userSlice.reducer;
