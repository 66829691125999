import * as Yup from "yup";
import i18n from "i18next";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(() => i18n.t("invalidEmailAddress"))
    .required(() => i18n.t("emailIsRequired")),
  password: Yup.string()
    .min(8, () => i18n.t("passwordMustBeAt"))
    .matches(/^(?=.*[A-Z])/, () => i18n.t("passwordMustContainAt"))
    .required(() => i18n.t("passwordIsRequired")),
});

export const validationSchemaSignUp = Yup.object().shape({
  email: Yup.string()
    .email(() => i18n.t("invalidEmailAddress"))
    .required(() => i18n.t("emailIsRequired")),
  password: Yup.string()
    .min(8, () => i18n.t("passwordMustBeAt"))
    .matches(/^(?=.*[A-Z])/, () => i18n.t("passwordMustContainAt"))
    .required(() => i18n.t("passwordIsRequired")),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], () => i18n.t("passwordMustMatch"))
    .required(() => i18n.t("repeatPasswordIsRequired")),
});

export const validationSchemaResetPassword = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, () => i18n.t("newPasswordMustBe"))
    .matches(/^(?=.*[A-Z])/, () => i18n.t("newPasswordMustContain"))
    .required(() => i18n.t("nePasswordIsRequired")),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], () => i18n.t("passwordsMustMatch"))
    .required(() => i18n.t("confirmPasswordIsRequired")),
});

export const validationSchemaForgotPassword = Yup.object().shape({
  email: Yup.string()
    .email(() => i18n.t("invalidEmailAddress"))
    .required(() => i18n.t("emailIsRequired")),
});
