import React from "react";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../utils/navigationUtils";
import ServiceList from "../components/services/ServiceList";
import { secondaryFixed } from "../ui-elements/button";
import { useNavigate } from "react-router-dom";

const About = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data = [
    { id: 1, name: "Cleaning", url: "/assets/services/1.png" },
    { id: 2, name: "Handiman", url: "/assets/services/2.png" },
    { id: 3, name: "Nurse", url: "/assets/services/3.png" },
    { id: 4, name: "Lecturer", url: "/assets/services/4.png" },
    { id: 5, name: "Babysitter", url: "/assets/services/5.png" },
    { id: 6, name: "Dogsitter", url: "/assets/services/6.png" },
    { id: 7, name: "Massage", url: "/assets/services/7.png" },
    { id: 8, name: "Psychologist", url: "/assets/services/8.png" },
    { id: 9, name: "Psychology", url: "logo1.png" },
    { id: 10, name: "Plumbing", url: "logo2.png" },
    { id: 11, name: "Cleaning", url: "logo3.png" },
    { id: 12, name: "Construction", url: "logo4.png" },
    { id: 13, name: "Electricity", url: "logo5.png" },
    { id: 14, name: "Barber", url: "logo6.png" },
    { id: 15, name: "Makeup", url: "logo6.png" },
    { id: 16, name: "Interior Design", url: "logo6.png" },
    { id: 17, name: "Psychology", url: "logo1.png" },
    { id: 18, name: "Plumbing", url: "logo2.png" },
  ];

  return (
    <div className="flex flex-col  gap-[70px] pt-[20px] lg:pt-[122px] pb-[94px] ">
      <div className="flex flex-col lg:flex-row justify-center items-center gap-8  lg:gap-[119px]">
        <div className="max-w-[487px] flex flex-col items-center lg:items-start lg:flex-wrap justify-center lg:gap-4 px-2  lg:px-0">
          <span className=" prose-t46_bold lg:prose-t58_bold pb-[12px] flex flex-wrap justify-center  lg:justify-start gap-2">
            <span className="text-primary ">{t("who")}</span>
            {t("areWe")}
          </span>
          <p className="prose-t18_regular text-a070 text-center lg:text-start">
            {t("ourMissionIsSimple")}
          </p>
        </div>
        <img
          src="/assets/aboutUs.webp"
          alt="about"
          className="max-h-[296px] lg:rounded-md"
        />
      </div>
      {/* Section 2 */}
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col items-center gap-4 pb-8 lg:pb-10 px-2 lg:px-0">
          <div className=" flex items-center gap-2">
            <h2 className="prose-t32_regular lg:prose-t40_regular">
              {t("our")}
            </h2>
            <h2 className="text-primary prose-t32_bold lg:prose-t40_bold">
              {t("services1")}
            </h2>
          </div>
          <p className="text-a070 text-center max-w-[552px]">
            {t("connectingYouWithTrusted1")}
          </p>
        </div>
        <ServiceList data={data} />
        <div className="flex justify-center mt-8">
          <button
            className={secondaryFixed}
            onClick={() => navigateTo(navigate, "/services")}
          >
            {t("seeAll")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
