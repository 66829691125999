import React from "react";

const Pagination = ({
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageOptions,
  gotoPage,
}) => {
  return (
    <div className="mt-5">
      <div className="flex justify-center items-center gap-4">
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="disabled:opacity-50 "
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 18L8 12L14 6L15.4 7.4L10.8 12L15.4 16.6L14 18Z"
              fill="#EE5222"
            />
          </svg>
        </button>

        {[...Array(pageOptions.length).keys()].map((pageNumber) => (
          <button
            key={pageNumber + 1}
            className={`${
              pageIndex === pageNumber
                ? "active text-white bg-primary border border-primary"
                : "text-a060 border border-a030 hover:bg-a05"
            } h-6 w-6 prose-t16_medium flex items-center justify-center gap-6 `}
            onClick={() => gotoPage(pageNumber)}
          >
            {pageNumber + 1}
          </button>
        ))}

        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="disabled:opacity-50"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6 12L8 7.4L9.4 6L15.4 12L9.4 18L8 16.6L12.6 12Z"
              fill="#EE5222"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
