import { createSlice } from "@reduxjs/toolkit";

export const popUpSlice = createSlice({
  name: "popUp",
  initialState: {
    isOpenPopUp: false,
    requestStatus: null,
  },
  reducers: {
    changePopUpStatus: (state, action) => {
      state.isOpenPopUp = action.payload;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
  },
});

export const { changePopUpStatus, setRequestStatus } = popUpSlice.actions;

export const selectIsOpenModal = (state) => state.popUp.isOpenPopUp;
export const selectRequestStatus = (state) => state.popUp.requestStatus;

export default popUpSlice.reducer;
