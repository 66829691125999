import axios from "axios";
import {
  requestErrorInterceptor,
  requestInterceptor,
} from "./interceptors/requestInterceptor";
import {
  responseErrorInterceptor,
  responseInterceptor,
} from "./interceptors/responseInterceptor";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: "https://myservice.am/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

// Apply interceptors to the instance
axiosInstance.interceptors.request.use(
  requestInterceptor,
  requestErrorInterceptor
);
axiosInstance.interceptors.response.use(
  responseInterceptor,
  responseErrorInterceptor
);

export default axiosInstance;
