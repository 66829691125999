import React from "react";
import UserProfile from "../components/userProfile/UserProfile";

const UserProfilePage = () => {
  return (
    <div>
      <UserProfile />
    </div>
  );
};

export default UserProfilePage;
