import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  changeStatus,
  closeModal,
} from "../../redux/slices/loginModal/modalSlice";
import ResetPasswordForm from "./Form/ResetPasswordForm";

// Import the form styles
import {
  formDescription,
  formTitle,
} from "../../ui-elements/formStyles/formStyle.js";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isArmenian, setIsArmenian] = useState(false);

  useEffect(() => {
    const currentLanguage = i18n.language;
    setIsArmenian(currentLanguage === "am");
  }, [i18n.language]);

  const handleClick = () => {
    dispatch(closeModal(false));
    dispatch(changeStatus(""));
  };

  return (
    <>
      <div className="hidden lg:flex lg:w-1/2 ">
        <img
          alt="banner"
          src="/assets/resetPassword.webp"
          className="w-full h-full"
        />
      </div>
      <div className="w-full lg:w-1/2  h-full flex flex-col justify-center items-center ">
        <div className="flex flex-col  gap-2    ">
          <span
            onClick={handleClick}
            className="prose-t14_regular text-a050 hover:text-a040 cursor-pointer"
          >
            {t("goToHomePage")}
          </span>
          <h1 className={formTitle}>
            {isArmenian
              ? t("resetPassword")
                  .split(" ")
                  .map((word, index) => (
                    <React.Fragment key={index}>
                      {index > 0 && <br />}
                      {word}
                    </React.Fragment>
                  ))
              : t("resetPassword")}
          </h1>
          <span className={formDescription}>{t("pleaseChooseANew")}</span>
        </div>
        <ResetPasswordForm />
      </div>
    </>
  );
};

export default ResetPassword;
