import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StarRating from "../../../../helpers/StarRating";
import { useNavigate } from "react-router-dom";

const TableBody = ({ rows, prepareRow, getTableBodyProps }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reorderRow, setReorderRow] = useState(null);

  const handleSvgClick = (rowIndex) => {
    setReorderRow((prevState) => (prevState === rowIndex ? null : rowIndex));
  };

  const handleReorderClick = () => {
    // navigate("/reorder-page"); // Replace with the desired reorder page
  };

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row, i) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()} key={i} className="border-b">
            <td className="px-4 py-6 border-b text-a060">
              {row.original.serviceProvider}
            </td>
            <td className="px-4 py-6 prose-t16_medium text-black">
              {row.original.segment}
            </td>
            <td className="px-4 py-6 text-a060">{row.original.startDate}</td>
            <td className="px-4 py-6 prose-t16_medium text-black">
              {row.original.status}
            </td>
            <td className="px-4 py-6 flex gap-1.5">
              <StarRating count={row.original.serviceRating} />
            </td>
            <td className="py-6 relative">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={() => handleSvgClick(i)}
              >
                <path
                  d="M6 14C5.45 14 4.97917 13.8042 4.5875 13.4125C4.19583 13.0208 4 12.55 4 12C4 11.45 4.19583 10.9792 4.5875 10.5875C4.97917 10.1958 5.45 10 6 10C6.55 10 7.02083 10.1958 7.4125 10.5875C7.80417 10.9792 8 11.45 8 12C8 12.55 7.80417 13.0208 7.4125 13.4125C7.02083 13.8042 6.55 14 6 14ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM18 14C17.45 14 16.9792 13.8042 16.5875 13.4125C16.1958 13.0208 16 12.55 16 12C16 11.45 16.1958 10.9792 16.5875 10.5875C16.9792 10.1958 17.45 10 18 10C18.55 10 19.0208 10.1958 19.4125 10.5875C19.8042 10.9792 20 11.45 20 12C20 12.55 19.8042 13.0208 19.4125 13.4125C19.0208 13.8042 18.55 14 18 14Z"
                  fill="#666666"
                />
              </svg>
              {reorderRow === i && (
                <button
                  onClick={handleReorderClick}
                  className="absolute left-[-150px]  w-[189px] h-[52px] shadow-lg rounded-md bg-white text-black hover:bg-a05 text-left z-10 px-4"
                >
                  {t("reorder")}
                </button>
              )}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
