import React from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import TableHeader from "./TableHeader"; // Adjust the import path as necessary
import TableBody from "./TableBody"; // Adjust the import path as necessary
import Pagination from "./Paginathion";

// Mock Data
const data = [
  {
    serviceProvider: "Provider A",
    segment: "Segment 1",
    startDate: "2023-05-01",
    status: "Finished",
    serviceRating: "5",
  },
  {
    serviceProvider: "Provider B",
    segment: "Segment 2",
    startDate: "2023-04-15",
    status: "Pending",
    serviceRating: "4",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider C",
    segment: "Segment 3",
    startDate: "2023-03-20",
    status: "Canceled",
    serviceRating: "2",
  },
  {
    serviceProvider: "Provider A",
    segment: "Segment 1",
    startDate: "2023-05-01",
    status: "Finished",
    serviceRating: "5",
  },
  {
    serviceProvider: "Provider A",
    segment: "Segment 1",
    startDate: "2023-05-01",
    status: "Finished",
    serviceRating: "5",
  },
  {
    serviceProvider: "Provider A",
    segment: "Segment 1",
    startDate: "2023-05-01",
    status: "Finished",
    serviceRating: "5",
  },
];

// Custom sorting function for dates
const dateStringSorter = (rowA, rowB, columnId, desc) => {
  const dateA = new Date(rowA.values[columnId]);
  const dateB = new Date(rowB.values[columnId]);
  return dateA - dateB;
};

// Define Columns
const columns = [
  {
    Header: "Service Provider",
    accessor: "serviceProvider",
    id: "serviceProvider",
    sortType: "basic",
  },
  {
    Header: "Segment",
    accessor: "segment",
    id: "segment",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    id: "startDate",
    sortType: dateStringSorter,
  },
  {
    Header: "Status",
    accessor: "status",
    id: "status",
  },
  {
    Header: "Service Rating",
    accessor: "serviceRating",
    id: "serviceRating",
  },
  {
    Header: "",
    accessor: "reorder",
    id: "reorder",
  },
];

const OrderHistory = () => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }, // Show 10 items per page
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <div className="border">
        <table {...getTableProps()} className="w-full  ">
          <TableHeader headerGroups={headerGroups} />
          <TableBody
            rows={page}
            prepareRow={prepareRow}
            getTableBodyProps={getTableBodyProps}
          />
        </table>
      </div>
      <Pagination
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />
    </div>
  );
};
export default OrderHistory;
