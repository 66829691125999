import React from "react";

const TableHeader = ({ headerGroups }) => {
  return (
    <thead className="">
      {headerGroups.map((headerGroup, index) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroup-${index}`}>
          {headerGroup.headers.map((column) => (
            <th
              key={column.id}
              className="py-2 border-b text-start px-4 bg-[#F2F2F2] text-a050  prose-t14_medium"
              {...(column.id === "serviceProvider" || column.id === "startDate"
                ? column.getHeaderProps(column.getSortByToggleProps())
                : column.getHeaderProps())}
            >
              <div className="flex items-center">
                {column.render("Header")}
                {["serviceProvider", "startDate"].includes(column.id) && (
                  <span className="ml-2">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <svg
                          width="6"
                          height="5"
                          viewBox="0 0 6 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 5L0.401925 0.404255L5.59808 0.404255L3 5Z"
                            fill="#333333"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="6"
                          height="5"
                          viewBox="0 0 6 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 0L5.59807 4.59574H0.401924L3 0Z"
                            fill="#333333"
                          />
                        </svg>
                      )
                    ) : (
                      <svg
                        width="6"
                        height="5"
                        viewBox="0 0 6 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 5L0.401925 0.404255L5.59808 0.404255L3 5Z"
                          fill="#BFBFBF"
                        />
                      </svg>
                    )}
                  </span>
                )}
              </div>
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};

export default TableHeader;
