import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import StarRating from "../../helpers/StarRating";
import UserComments from "./UserComments";
import ServiceList from "../services/ServiceList";

const OnePartner = () => {
  const { t } = useTranslation();

  const data = [
    { id: 1, name: "Cleaning", url: "/assets/services/1.png", stars: 4 },
    { id: 2, name: "Handiman", url: "/assets/services/2.png", stars: 3 },
    { id: 3, name: "Nurse", url: "/assets/services/3.png", stars: 5 },
    { id: 4, name: "Lecturer", url: "/assets/services/4.png", stars: 4 },
    { id: 5, name: "Babysitter", url: "/assets/services/5.png", stars: 5 },
    { id: 6, name: "Dogsitter", url: "/assets/services/6.png", stars: 2 },
    { id: 7, name: "Massage", url: "/assets/services/7.png", stars: 5 },
    { id: 8, name: "Psychologist", url: "/assets/services/8.png", stars: 1 },
    { id: 9, name: "Psychology", url: "logo1.png", stars: 4 },
    { id: 10, name: "Plumbing", url: "logo2.png", stars: 3 },
    { id: 11, name: "Cleaning", url: "logo3.png", stars: 3 },
    { id: 12, name: "Construction", url: "logo4.png", stars: 5 },
    { id: 13, name: "Electricity", url: "logo5.png", stars: 5 },
    { id: 14, name: "Barber", url: "logo6.png", stars: 2 },
    { id: 15, name: "Makeup", url: "logo6.png", stars: 4 },
    { id: 16, name: "Interior Design", url: "logo6.png", stars: 4 },
  ];

  const commentsRef = useRef(null);

  const scrollToComments = () => {
    commentsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {/* banner */}
      <div className="flex flex-col items-center">
        <div className="relative w-full z-0">
          <img
            alt="partner"
            src="/assets/onePartner.webp"
            className="h-[338px] w-full object-cover"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50">
            <div className="flex flex-col gap-3.5 justify-center items-center">
              <h2 className="prose-t40_bold text-white">Nor Tun</h2>
              <p className="prose-t16_regular text-white max-w-[362px] text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <span className="flex gap-2">
                <StarRating count={4} />
                <span
                  className="text-white prose-t14_regular lg:mt-0.5 cursor-pointer"
                  onClick={scrollToComments}
                >
                  ({data.length})
                </span>
              </span>
            </div>

            <div className="absolute inset-x-0 bottom-[-99px] md:bottom-[-50px] flex justify-center z-10">
              <div
                className="w-[336px] md:w-[589px] h-[178px] md:h-[100px] bg-white shadow-md rounded-lg 
                            flex flex-col md:flex-row items-center justify-evenly gap-[43px] md:px-[28px] pt-5 md:pt-0"
              >
                <img
                  alt="logo"
                  src="/assets/nortun.png"
                  className="w-[208px] h-[56px]"
                />
                <div className="flex justify-between items-center">
                  <span className="w-[150px] h-[80px] flex items-start md:items-center justify-center prose-t16_regular text-a080">
                    Category
                  </span>
                  <span className="w-[150px] h-[80px] flex items-start md:items-center justify-center prose-t16_regular text-a080">
                    Expert
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* description */}
      <div className="hidden pt-[70px] md:flex items-center justify-center">
        <p className="text-center text-a070 w-[652px]">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived
        </p>
      </div>
      {/* service List */}
      <div className="pt-[80px] md:pt-0 flex flex-col items-center justify-center gap-8 mt-[31px]">
        <h3 className="text-a070 prose-t24_bold">{t("services")}</h3>
        <ServiceList data={data} />
      </div>
      {/* user comments */}
      <div className="pb-[64px]" ref={commentsRef}>
        <UserComments />
      </div>
    </div>
  );
};

export default OnePartner;
