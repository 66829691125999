import { createSlice } from "@reduxjs/toolkit";

export const footerSlice = createSlice({
  name: "footer",
  initialState: {
    isShow: true,
  },
  reducers: {
    setIsShowScrollUpButton: (state, action) => {
      state.isShow = action.payload;
    },
  },
});

export const { setIsShowScrollUpButton } = footerSlice.actions;

export const selectIsShowScrollUpButton = (state) => state.footer.isShow;

export default footerSlice.reducer;
