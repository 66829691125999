import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ForgotPasswordForm from "./Form/ForgotPasswordForm";
import {
  formDescription,
  formTitle,
} from "../../ui-elements/formStyles/formStyle.js";
import { changeStatus } from "../../redux/slices/loginModal/modalSlice";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(changeStatus("SignIn"));
  };

  return (
    <>
      <div className="hidden lg:flex lg:w-1/2">
        <img
          alt="banner"
          src="/assets/forgotPassword.webp"
          className="w-full h-full"
        />
      </div>
      <div className="w-full lg:w-1/2 h-full flex flex-col justify-center items-center">
        <div className="flex flex-col lg:w-1/2 justify-center gap-2">
          <span
            onClick={handleClose}
            className="prose-t14_regular text-[#5d5d5d] hover:text-a050 cursor-pointer"
          >
            {t("backToLogin")}
          </span>
          <h1 className={formTitle}>{t("forgotPassword?")}</h1>
          <span className={formDescription}>{t("noWorriesWeWill")}</span>
          <ForgotPasswordForm />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
