import { createSlice } from "@reduxjs/toolkit";

export const pendingService = createSlice({
  name: "pendingService",
  initialState: {
    isOpen: false,
    status: "",
    icon: false,
  },
  reducers: {
    openPendingService: (state, action) => {
      state.isOpen = action.payload;
    },
    closePendingService: (state, action) => {
      state.isOpen = action.payload;
    },
    changePendingServiceStatus: (state, action) => {
      state.status = action.payload;
    },
    IsShowIcon: (state, action) => {
      state.icon = action.payload;
    },
  },
});

export const {
  openPendingService,
  closePendingService,
  changePendingServiceStatus,
  IsShowIcon,
} = pendingService.actions;

export const selectPendingServiceIsOpen = (state) =>
  state.pendingService.isOpen;
export const selectPendingServiceStatus = (state) =>
  state.pendingService.status;
export const selectIsShowIcon = (state) => state.pendingService.icon;

export default pendingService.reducer;
