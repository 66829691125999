import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { MdOutlineClose } from "react-icons/md";
import {
  changeStatus,
  closeModal,
  selectModalIsOpen,
  selectModalStatus,
} from "../../redux/slices/loginModal/modalSlice";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ReactModal from "react-modal";
import { selectIsOpenModal } from "../../redux/slices/popUp/popUpSlice";
import PopUpWindow from "../pop-up/PopUpWindow";

ReactModal.setAppElement("#root");

const LoginModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectModalIsOpen);
  const status = useSelector(selectModalStatus);
  const isOpenPopUp = useSelector(selectIsOpenModal);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const handleClose = () => {
    dispatch(closeModal(false));
    dispatch(changeStatus(""));
  };

  let modalContent = null;
  switch (status) {
    case "SignIn":
      modalContent = <SignIn />;
      break;
    case "SignUp":
      modalContent = <SignUp />;
      break;
    case "ForgotPassword":
      modalContent = <ForgotPassword />;
      break;
    case "ResetPassword":
      modalContent = <ResetPassword />;
      break;
    default:
      modalContent = null;
  }
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 50,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "100%",
      height: "100%",
      padding: 0,
      border: "none",
      overflow: "hidden",
    },
  };

  return (
    <AnimatePresence>
      <ReactModal isOpen={isOpen} style={customStyles}>
        <motion.div
          className=" w-full h-full flex items-center justify-center "
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.5, opacity: 0 }}
          transition={{ type: "spring", stiffness: 260, damping: 30 }}
        >
          <div className="bg-white w-full h-full sm:h-auto   max-h-full lg:h-full  sm:overflow-auto  ">
            {/* Close button */}
            <div className=" fixed top-0 right-0 p-4">
              <button
                className="text-gray-600 hover:text-gray-900"
                onClick={handleClose}
              >
                <MdOutlineClose className="w-6 h-6" />
              </button>
            </div>
            <div className=" flex  h-full  md:h-auto lg:h-full sm:py-4 lg:py-0">
              {modalContent}
            </div>
          </div>
        </motion.div>
        {isOpenPopUp && <PopUpWindow />}
      </ReactModal>
    </AnimatePresence>
  );
};

export default LoginModal;
