import React from "react";
import { useTranslation } from "react-i18next";
import { navigateTo } from "../../utils/navigationUtils";
import {
  FaFacebook,
  FaInstagramSquare,
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FooterForm from "./footerForm/FooterForm";

const MobileFooter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="py-5 bg-a05">
      <div className="flex flex-col   ">
        {/* Social Media */}
        <div className="flex flex-col items-center gap-2 ">
          <span className="prose-t14_medium ">{t("socialMedia")}</span>
          <div className="flex gap-2 text-2xl">
            <FaFacebook />
            <FaInstagramSquare />
          </div>
        </div>
      </div>
      {/* Main */}
      <div className="flex justify-between gap-4 px-6 pt-5">
        <div className="flex flex-col gap-4">
          {/* Stay in Touch */}
          <div className="flex flex-col">
            <span className="prose-t14_medium">{t("stayInTouch")}</span>
            <div className="flex flex-col gap-2">
              <span className="prose-t14_bold text-a060 ">
                sales@myservices.me
              </span>
              <span className="prose-t14_bold text-a060">
                support@myservices.me
              </span>
              <span className="prose-t14_bold text-a060">
                +374 (44) 123-456
              </span>
            </div>
          </div>
          {/* Online payments  */}
          <div className="flex flex-col gap-2">
            <span className="prose-t14_medium">{t("onlinePayments")}</span>
            <div className="flex gap-2">
              <FaCcVisa />
              <FaCcMastercard />
              <FaCcPaypal />
            </div>
          </div>
        </div>
        {/* Company */}
        <div className="flex flex-col gap-2">
          <span className="prose-t14_medium">{t("company")}</span>
          <div className="flex flex-col gap-2">
            <span
              className="prose-t14_regular text-a060 hover:text-a070 cursor-pointer"
              onClick={() => navigateTo(navigate, "/services")}
            >
              {t("services")}
            </span>
            <span
              className="prose-t14_regular text-a060 hover:text-a050 cursor-pointer"
              onClick={() => navigateTo(navigate, "/partners")}
            >
              {t("partners")}
            </span>
            <span
              className="prose-t14_regular text-a060 hover:text-a050 cursor-pointer"
              onClick={() => navigateTo(navigate, "/about")}
            >
              {t("aboutUs")}
            </span>
            <span
              className="prose-t14_regular text-a060 hover:text-a050 cursor-pointer"
              onClick={() => navigateTo(navigate, "/contact")}
            >
              {t("contactUS")}
            </span>
            <span
              className="prose-t14_regular text-a060 hover:text-a050 cursor-pointer"
              onClick={() => navigateTo(navigate, "/privacy-policy")}
            >
              {t("privacyPolicy")}
            </span>
          </div>
        </div>
      </div>
      {/* Subscribe  */}
      <div className="flex flex-col items-center gap-2.5 pt-8">
        <span className="prose-t14_regular  text-center">
          {t("subscribeToOurNewsletter")}
        </span>
        <FooterForm />
      </div>
      <div className="h-[64px] border-t-[1px] mt-6 md:mt-4 border-a020 flex justify-center items-center text-center">
        <span className="prose-t14_regular text-a060 text-center px-[60px]">
          {t("copyright")}
        </span>
      </div>
    </div>
  );
};

export default MobileFooter;
