import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchInput from "../components/searchInput/SearchInput";
import PartnersModal from "../components/partners/partnersModal/PartnersModal";
import PartnersCard from "../components/partners/partnersCard/PartnersCard";

const Partners = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    document.body.classList.add("isOpenModal"); // Add class to prevent scrolling
  };

  const handleClose = () => {
    document.body.classList.remove("isOpenModal");

    setShowModal(false);
  };

  useEffect(() => {
    return () => {
      // Cleanup when the component unmounts
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <div className={showModal ? "customClass" : ""}>
      {showModal && (
        <PartnersModal showModal={showModal} onClose={handleClose} />
      )}
      {/* banner */}
      <div className="relative ">
        <img
          src="/assets/partners.jpg"
          alt="partners"
          className="h-[338px] w-full object-cover brightness-50"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <span className="flex gap-2 prose-t32_regular lg:prose-t40_regular text-white">
            {t("our")}
            <span className="text-primary lg:prose-t40_bold">
              {t("partners1")}
            </span>
          </span>
          <p className="text-white max-w-[552px] text-center px-3 sm:px-0">
            {t("joinMyServiceTodayAnd")}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-5 pt-8  items-center">
        <SearchInput />
        <PartnersCard />
      </div>
      {/* become a partner  */}
      <div className=" w-full  flex  items-center  lg:justify-evenly mt-[70px] lg:mt-[134px] mb-[70px] ">
        <div className="flex flex-col w-full lg:max-w-[592px] ">
          <div className="w-full flex flex-col sm:items-center md:items-start lg:items-start  px-4 xl:px-0">
            <span className="prose-t20_medium lg:prose-t32_medium  text-a040  text-center ">
              {t("wantToCollaborate")}
            </span>
            <h2 className="prose-t40_bold lg:prose-t58_bold text-primary mt-1 text-center lg:text-left">
              {t("becomeAPartner")}
            </h2>
            <span className="prose-t18_regular lg:prose-t24_regular text-center lg:text-start text-a070 mt-4">
              {t("feelFreeToContact")}
            </span>
          </div>
          <div className="w-full flex justify-center md:justify-start lg:justify-start px-3 xl:px-0">
            <button
              className=" px-[95.5px] md:px-[42.5px] py-4 text-white bg-primary rounded-3xl  prose-t16_medium mt-4 hover:filter hover:brightness-90"
              onClick={openModal}
            >
              {t("becomeAPartner1")}
            </button>
          </div>
        </div>
        <div className="hidden md:flex md:justify-center lg:justify-start rounded-xl  px-3  xl:px-0">
          <img
            src="/assets/becomePartner.webp"
            alt="become a partner"
            className="rounded-md  w-[487px] object-fill"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
