import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  address: Yup.string().required("Address is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Invalid phone number")
    .required("Phone number is required"),
  paymentMethod: Yup.string().required("Payment method is required"),
});
