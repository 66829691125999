import React from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import ContactForm from "../components/contact/ContactForm";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <div className="flex flex-col items-center gap-1 ">
          <h1 className="prose-t32_bold md:prose-t40_bold pt-5 md:pt-[60px]">
            {t("contactUS1")}
          </h1>
          <p className=" max-w-2xl prose-t16_regular text-a070 text-center">
            {t("reachOutViaEmail")}
          </p>
        </div>
        <div className="flex  justify-center items-center gap-4 md:gap-5 pt-6 sm:pt-4">
          <span className="flex items-center gap-2.5">
            <MdOutlineMail />
            <span className="text-a070 prose-t16_regular">
              info@service.com
            </span>
          </span>
          <span className="flex items-center gap-2.5">
            <MdOutlinePhone />
            <span className="text-a070 prose-t16_regular">
              +374 93 00 00 01
            </span>
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center pt-6 md:pt-7 pb-[64px] md:pb-[133px]">
        <div className="pt-28px md:w-[704px]  border border-a010 px-2 md:px-0">
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
