import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  errorMessageStyle,
  formButton,
  formInput,
} from "../../../ui-elements/formStyles/formStyle";
import { validationSchemaForgotPassword } from "./validation/signInFormValidation";
import { forgotPassword } from "../../../axios/api/loginApi";
import {
  changePopUpStatus,
  setRequestStatus,
} from "../../../redux/slices/popUp/popUpSlice";

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const responseData = await forgotPassword(values);
    dispatch(changePopUpStatus(true));
    dispatch(setRequestStatus(responseData));
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchemaForgotPassword}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className="mb-3.5">
            <Field
              type="email"
              name="email"
              placeholder={t("enterEmail")}
              className={formInput}
              style={{
                border: `1px solid ${
                  errors.email && touched.email ? "red" : "transparent"
                }`,
              }}
            />
            <div className="min-h-[20px]">
              <ErrorMessage
                name="email"
                component="div"
                className={errorMessageStyle}
              />
            </div>
          </div>
          <button type="submit" className={`${formButton} `}>
            {t("sendResetLink")}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
